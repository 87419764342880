"use client";
import React from "react";
import { GA_PARAMS, IBankAccount, IFlexibleProducts, IInjectProps, IKycLevel } from "@aspen/model";
import { Button } from "antd";
import { i18nUtil, REFERENCE_CURRENCY, WALLET_PATHS } from "@aspen/libs";
import { withRouter } from "next/router";
// const YieldComponent = dynamic(() => import("../home/index"));
import { YieldComponent } from "../index";
import styles from "@aspen/theme/Home.module.less";

interface IProps extends IInjectProps {
  yieldProducts: IFlexibleProducts[];
  gaExtra?: GA_PARAMS; // 埋点用
  showGuideModal: (level: IKycLevel, isClose?: boolean) => boolean;
}
interface IState {
  showModal: boolean;
  circleInfo: IBankAccount | null;
}

class AssetSituationalNoDepositView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
      circleInfo: null
    };
  }
  getCircleInfo = (params) => {
    this.setState(
      {
        circleInfo: params
      },
      () => {
        this.showCircleModal();
      }
    );
  };
  showCircleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  goToDepositFiat = () => {
    this.props.router.push({
      pathname: WALLET_PATHS.WALLET_DEPOSIT_OTC
    });
    };

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    const { yieldProducts, gaExtra } = this.props;

    return (
      <section className="!w-full h-full flex flex-col justify-start">
        <p className="w-full text-base font-bold font-CommonBold text-white leading-6">
          {i18nUtil.formatMessage(
            { id: "overview.assetSituational.title" },
            { currency: REFERENCE_CURRENCY }
          )}
        </p>
        <div className="flex-1 flex justify-center items-center">
          <div>
            <p className="mt-5 mb-3 text-center text-base font-CommonMedium font-medium text-white leading-6">{intl["home.no.depsoit"]}</p>
            <div className="mx-auto mb-3 text-center">
              <Button
                type="primary"
                className="cursor-pointer mr-4"
                onClick={() => {
                  this.props.router.push(WALLET_PATHS.WALLET_DEPOSIT);
                }}>
                {intl["button.deposit.crypto.currency"]}
              </Button>
              <Button className={styles.actionBtn} onClick={this.goToDepositFiat}>
                {intl["button.deposit.fiat"]}
              </Button>
            </div>
            <div className="flex justify-between mb-4">
              {yieldProducts?.map((item: IFlexibleProducts) => (
                <YieldComponent
                  yieldProductsItem={item}
                  key={item.id}
                  type="noDeposit"
                  gaExtra={gaExtra}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }

  shouldComponentUpdate(nextProps, nextState): boolean {
    return (
      JSON.stringify(nextProps.yieldProducts) != JSON.stringify(this.props.yieldProducts) ||
      nextState.showModal != this.state.showModal
    );
  }
}

export const AssetSituationalNoDeposit = withRouter(AssetSituationalNoDepositView);
