import React, { useEffect, useRef, useState } from "react";
import { Popover, Spin, Table } from "antd";
import { message, WithEmptyContent } from "@aspen/ui/index";
import {
  decimalPointNoMoreX,
  STRUCTURED_PATH,
  GA_EVENT_NAME,
  i18nUtil,
  numberToThousands,
  processAPR,
  reportEvent,
  showStrike,
  STRUCTURED_TYPES,
} from "@aspen/libs";
import styles from "@aspen/theme/Structured.module.less";
import type { IReqStructuredSpeList, IStructuredListProductModel } from "@aspen/model";
import { getStructuredSpecificList } from "@aspen/services";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useRouter } from "next/router";

interface IProps {
  type: string;
  underlying: string;
  quote: string;
  name: string;
}

const POLLING_TIMEOUT = 1500 * 10;

export function StructuredTableList(props: IProps) {
  const router = useRouter();
  const intl = i18nUtil.t();
  const { type, underlying, name, quote } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IStructuredListProductModel[]>([]);
  const [formatDateStrFiltered, setFormatDateStrFiltered] = useState<string[]>([]);
  const [columns, setColumns] = useState<ColumnsType<IStructuredListProductModel>>([]);
  const pollingRef = useRef<string | number | NodeJS.Timeout | null | undefined>(null);

  useEffect(() => {
    if (type && underlying) {
      _clearTimeout();
      resetColumns();
      getList();
    }
  }, [type, name]);
  useEffect(() => {
    _handleDataSource();
  }, [dataSource || formatDateStrFiltered]);

  useEffect(() => {
    return () => {
      _clearTimeout();
    };
  }, []);

  const resetColumns = () => {
    setColumns([
      {
        title: (
          <div className={styles.crossed}>
            <span className={styles.expiry}>{intl["column.expiry"]}</span>
            <span className={styles.target}>{intl["column.target"]}</span>
          </div>
        ),
        dataIndex: "strike",
        key: 0,
        render: (value: number) => (
          <span className={styles.strikeItem}>{`${numberToThousands(value)}${quote}`}</span>
        ),
        fixed: "left",
      },
    ]);
  };
  const _clearTimeout = () => {
    // @ts-ignore
    pollingRef?.current && clearTimeout(pollingRef.current);
    pollingRef.current = null;
  };

  const popoverContent = (record, index: number, j: number) => {
    const key = `estimateApr${j}`;
    return (
      <div key={index} className={styles.popoverContent}>
        <p>
          <span>{intl["column.target.price"]}</span>
          <span className={styles.contentRight}>
            {record?.strike ? showStrike(record?.strike, quote) : "--"}
          </span>
        </p>
        {record?.initialPrice ? (
          <p>
            <span>{intl["structured.item.realtime"]}</span>
            <span className={styles.contentRight}>
              {numberToThousands(decimalPointNoMoreX(record?.initialPrice, 6)) || "--"}
            </span>
          </p>
        ) : (
          <></>
        )}
        <p>
          <span>{intl["structured.modal.subscription.expiryDate"]}</span>
          <span className={styles.contentRight}>
            {formatDateStrFiltered[j] ? dayjs(formatDateStrFiltered[j]).format("MM-DD") : "--"}
          </span>
        </p>
        <p>
          <span>{intl["column.apr"]}</span>
          <span className={styles.contentRight}>
            {" "}
            {record[key] ? `${processAPR(record[key])}%` : "--"}
          </span>
        </p>
      </div>
    );
  };
  const tableRowItemPress = (record, j) => {
    reportEvent({
      moduleName: GA_EVENT_NAME.accumulator.subscribe,
      detailParams: {
        underlying: record?.underlyings?.[0],
        type: record?.types?.[0],
        id: record?.ids?.[j],
        name,
      },
    });
    if (record[`estimateApr${j}`]) {
      _clearTimeout();
      router.push({
        pathname: STRUCTURED_PATH.STRUCTURED_DUAL_SUBSCRIBE,
        query: {
          underlying: record?.underlyings[0],
          type: record?.types[0],
          id: record?.ids[j],
          name,
          productType: STRUCTURED_TYPES.dual,
        },
      });
    }
  };
  /**
   *
   * @param record 横向item的集合
   * @param index  竖向index
   * @param j 横向集合中的index
   */
  const _renderTableRowItem = (record, index, j) => {
    return (
      <div
        className={styles.estimateAprItem}
        onClick={() => {
          tableRowItemPress(record, j);
        }}>
        <Popover
          key={index}
          placement="rightBottom"
          title={`${name} ${type}`}
          content={popoverContent(record, index, j)}>
          <span className={styles.hoverItem}>
            {record[`estimateApr${j}`] ? `${processAPR(record[`estimateApr${j}`])}%` : "--"}
          </span>
        </Popover>
      </div>
    );
  };

  const _handleDataSource = () => {
    let _columns = columns.slice();
    if (_columns.length > 1)
      _columns = [
        {
          title: (
            <div className={styles.crossed}>
              <span className={styles.expiry}>{intl["column.expiry"]}</span>
              <span className={styles.target}>{intl["column.target"]}</span>
            </div>
          ),
          dataIndex: "strike",
          key: 0,
          render: (value: number) => (
            <span className={styles.strikeItem}>{`${numberToThousands(value)}${quote}`}</span>
          ),
          fixed: "left",
        },
      ];
    for (let j = 0; j < formatDateStrFiltered.length; j++) {
      _columns.push({
        title: formatDateStrFiltered[j],
        dataIndex: `estimateApr${j}`,
        key: j + 1,
        width: 200,
        render: (value: number, record, index: number) => _renderTableRowItem(record, index, j),
      });
    }
    setColumns(_columns);
  };
  // 初始化数据
  const getList = (refresh?: boolean) => {
    let params: IReqStructuredSpeList = {
      underlying,
      type,
      quote: quote,
    };
    let arr: any = [];
    // TODO， 类型声明 @田世杰，ig-nore移除
    let flag = -1; // 去重strike的标志位
    !refresh && setLoading(true);
    getStructuredSpecificList(params)
      .then(res => {
        const { code, data } = res;
        if (code === "0") {
          let expiryArr: string[] = [];
          let dateStrSortedArr: number[] = [];
          for (let i = 0; i < data?.length; i++) {
            let curItem = data && data[i];
            if (curItem?.strike === arr[flag]?.strike) {
              // 拼接符合antd-table的数据源，并符合prd展示
              const key = `estimateAprArr`;
              const keyUnderlyings = `underlyings`;
              const keyTypes = `types`;
              const keyIds = `ids`;
              const keyTime = `time`;
              arr[flag][keyUnderlyings].push(curItem?.underlying);
              arr[flag][keyTypes].push(curItem?.type);
              arr[flag][keyIds].push(curItem?.id);
              arr[flag][keyTime].push(dayjs(curItem?.expiry).format("MM-DD")?.replace("-", "."));
              arr[flag][key].push(curItem?.estimateApr);
            } else {
              arr.push({
                estimateApr0: 0, // table每一行所要展示的数据必须要在同一个object里 所以这里用【属性名+index】来标记
                strike: curItem?.strike,
                initialPrice: curItem?.initialPrice,
                estimateAprArr: [curItem?.estimateApr],
                underlyings: [curItem?.underlying],
                types: [curItem?.type],
                ids: [curItem?.id],
                time: [dayjs(curItem?.expiry).format("MM-DD")?.replace("-", ".")],
              });
              flag++;
            }
            // 确定表头日期
            dateStrSortedArr.push(data[i]?.expiry);
          }
          // 排序表头日期
          dateStrSortedArr.sort((a, b) => Number(a) - Number(b));
          for (const item of dateStrSortedArr) {
            let formatDateStr = dayjs(item).format("MM-DD");
            expiryArr.push(formatDateStr?.replace("-", "."));
          }
          // @ts-ignore
          let _formatDateStrFiltered = [...new Set(expiryArr)];
          // 根据日期和strike确定row的数据结构
          try {
            for (let j = 0; j < _formatDateStrFiltered.length; j++) {
              let index = 0;
              let dateNumDiff = 0; // 代表当前目标价下 与日期表头相比缺少几个日期
              while (index < _formatDateStrFiltered.length) {
                const curDataSource = arr[j];
                let curDate = _formatDateStrFiltered[index];
                // @ts-ignore
                if (curDataSource?.time.indexOf(curDate) !== -1) {
                  // @ts-ignore
                  if (arr[j])
                    arr[j][`estimateApr${index}`] =
                      // @ts-ignore
                      curDataSource?.estimateAprArr[dateNumDiff ? index - dateNumDiff : index];
                } else {
                  // @ts-ignore
                  arr[j][`estimateApr${index}`] = 0;
                  // @ts-ignore
                  arr[j]?.ids?.splice(index, 0, 0);
                  dateNumDiff++;
                }
                index++;
              }
            }
          } catch (err) {
            console.log(err);
          }
          setFormatDateStrFiltered(_formatDateStrFiltered);
          setDataSource(arr);
          if (data?.length) {
            pollingRef.current = setTimeout(() => {
              _clearTimeout();
              getList(true);
            }, POLLING_TIMEOUT);
          }
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch(err => {
        message.error(intl?.[err?.msg] ?? err?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <WithEmptyContent showEmpty>
      <Spin spinning={loading}>
        <Table
          sticky
          scroll={{ x: true }}
          className={styles.table}
          rowKey={record => record?.strike}
          bordered={false}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
      </Spin>
    </WithEmptyContent>
  );
}

export default React.memo(StructuredTableList);
