import { apikeyBugsnagBeta } from "../../constant/base";
import type { IEnvConfig } from "@aspen/model";

export const adgmBetaConfig: IEnvConfig = {
  env: "beta",
  host: {
    // dev: "http://8.219.137.127:19090/",
    dev: "https://api-ad-beta.test-aspendigital.co/",
    prod: "https://api-ad-beta.test-aspendigital.co/"
  },
  klineChartUrl: "",
  tvChartUrl: "https://tradingview-ad-beta.test-aspendigital.co/",
  websocket: {
    host: "wss://ws-ad-beta.test-aspendigital.co:8080"
  },
  apiKeyBugsnag: apikeyBugsnagBeta,
  webDomain: "https://portal-ad-beta.test-aspendigital.co",
  appOnlyDomain: "https://apponly-ad-beta.test-aspendigital.co/",
  domainUriPrefix: "",
  fallbackUrl: {
    android: "",
    ios: ""
  },
  packageName: {
    android: "",
    ios: ""
  },
  minimumVersion: {
    android: "",
    ios: ""
  },
  operationServer: "https://api-aoperation-ad-beta.test-aspendigital.co/",
  saasServer: "",
  appScheme: ""
};
